export default [
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#92C7E6"
        }
      ]
    },
    {
      featureType: "landscape",
      elementType: "geometry",
      stylers: [
        {
          color: "#EEEEEF"
        }
      ]
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#EEEEEF"
        }
      ]
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          color: "#EEEEEF"
        }
      ]
    },
    {
      featureType: "road.arterial",
      elementType: "geometry",
      stylers: [
        {
          color: "#EEEEEF"
        },
        {
          lightness: -20
        }
      ]
    },
    {
      featureType: "road.local",
      elementType: "geometry",
      stylers: [
        {
          color: "#EEEEEF"
        },
        {
          lightness: -17
        }
      ]
    },
    {
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#8D8D8D"
        },
        {
          visibility: "on"
        },
        {
          weight: 0.9
        }
      ]
    },
    {
      elementType: "labels.text.fill",
      stylers: [
        {
          visibility: "on"
        },
        {
          color: "#8D8D8D"
        }
      ]
    },
    {
      featureType: "poi",
      elementType: "labels",
      stylers: [
        {
          visibility: "simplified"
        }
      ]
    },
    {
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "transit",
      elementType: "geometry",
      stylers: [
        {
          color: "#EEEEEF"
        },
        {
          lightness: -10
        }
      ]
    },
    {},
    {
      featureType: "administrative",
      elementType: "geometry",
      stylers: [
        {
          color: "#1f8a70"
        },
        {
          weight: 0.7
        }
      ]
    }
  ];